export const BASE_ROUTE = '/recover'

export const HOME = '/'
export const LOGIN = '/login'
export const SEARCH = '/search'
export const ACCESSDENIED = '/accessdenied'
export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
export const ACCOUNTS = '/accounts'
export const ACCOUNT_FILE_VALIDATION = ACCOUNTS + '/file-validation'
export const STRATEGIES = '/strategies'
export const STRATEGY = '/strategy'
export const ACCOUNT_DETAIL = '/account-detail'
export const RECALL_ACCOUNT_FILE_VALIDATION =
  ACCOUNTS + '/recall-file-validation'
export const TRANSACTION_FILE_VALIDATION =
  ACCOUNTS + '/transaction-file-validation'
export const BUSINESS_SETTINGS = '/business-settings'
export const UPDATE_ACCOUNT_FILE_VALIDATION =
  ACCOUNTS + '/update-file-validation'

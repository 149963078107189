import { ErrorToast, SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import {
  AccountFileData,
  UpdateAccountFileData,
} from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const UpdateAccountFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  accountPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)

  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('creditorId') ||
    !stateData.hasOwnProperty('fileUploaded')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    ErrorToast('Error')
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.UpdateAccountFile)
  const { fileData, creditorId, fileUploaded } = stateData

  const mapToAccountFileObject = (input: any): UpdateAccountFileData => {
    const dataRow: UpdateAccountFileData = {
      rowIndex: input.rowindex,
      ecaid: input.ecaid,
      lender: input.lender,
      loanid: input.loanid,
      originalLoanAmount: input.fundamount,
      principalBalance: input.principalbalance,
      interestBalance: input.interestbalance,
      otherFeesBalances: input.feebalance,
      originalDate: input.defaultdate,
      writeOffDate: input.writeoffdate,
      defaultDate: input.defaultdate,
      firstDelinquencyDate: input.firstdelinquencydate,
      lastPaymentDate: input.lastpaymentdate,
      lastPaymentAmount: input.lastpaymentamount,
      dob: input.dob,
      firstName: input.firstname,
      lastName: input.lastname,
      mi: input.mi,
      ssn: input.ssn,
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      state: input.state,
      zip: input.zip,
      country: input.country,
      product: input.product,
      affinity: input.affinity,
      apr: input.apr,
      accountSecured: input.accountsecured,
      additionalAccountNumber1: input.additionalaccountnumber1,
      additionalAccountNumber2: input.additionalaccountnumber2,
      additionalAccountNumber3: input.additionalaccountnumber3,
      bureauAccountCreditReportedTo: input.bureauaccountcreditreportedt,
      coBorrowerFlag: input.coborrowerflag,
      numberofRefinances: input.numberofrefinances,
      settlementAgreement: input.settlementagreement,
      settlementOfferAmount: input.settlementofferamount,
      buyersOnTitle: input.buyersontitle,
      seller: input.seller,
      consumerCreditScore_TimeofLending:
        input.consumercreditscore_timeoflending,
      checkNumber: input.checknumber,
      checkDate: input.checkdate,
      checkReturnDate: input.checkreturndate,
      homeStatus: input.homestatus,
      phnCountryCode: input.phncountrycode,
      homePhone: input.homephone,
      mobilePhone: input.mobilephone,
      workPhone: input.workphone,
      phoneExtension: input.phoneextension,
      email: input.email,
      employer: input.employer,
      jobTitle: input.jobtitle,
      monthsEmployed: input.monthsemployed,
      employmentStartDate: input.employmentstartdate,
      payMethod: input.paymethod,
      employeeLastPaid: input.employeelastpaid,
      employeeSupervisor: input.employeesupervisor,
      employeeSupervisorPhone: input.employeesupervisorphone,
      payFrequency: input.payfrequency,
      dayOfPay: input.dayofpay,
      bankAccountType: input.bankaccounttype,
      bankName: input.bankname,
      routing: input.routing,
      bankAccountNumber: input.bankaccountnumber,
      fundDate: input.funddate,
      fundAmount: input.fundamount,
      feeBalance: input.feebalance,
      totalBalance: input.totalbalance,
      totalAmountPaid: input.totalamountpaid,
      writeOffReason: input.writeoffreason,
      lastPaymentStatus: input.lastpaymentstatus,
      initialReturnCode: input.initialreturncode,
      lastReturnCode: input.lastreturncode,
      lastWorkedDate: input.lastworkeddate,
      lastMissedPaymentDate: input.lastmissedpaymentdate,
      lastDueDate: input.lastduedate,
      idType: input.idtype,
      idNumber: input.idnumber,
      idState: input.idstate,
      storeName: input.storename,
      storeAddress: input.storeaddress,
      storeCity: input.storecity,
      storeState: input.storestate,
      merchantStoreID: input.merchantstoreid,
      totalPaidPriorToDefault: input.totalpaidpriortodefault,
      totalPaidPostDefault: input.totalpaidpostdefault,
      interestRate: input.interestrate,
      fee1: input.fee1,
      fee2: input.fee2,
      fee3: input.fee3,
      fee4: input.fee4,
      fee5: input.fee5,
      coBorrowerSSN: input.coborrowerssn,
      coBorrowerDOB: input.coborrowerdob,
      coBorrowerFirstName: input.coborrowerfirstname,
      coBorrowerLastName: input.coborrowerlastname,
      coBorrowerMI: input.coborrowermi,
      coBorrowerAddress1: input.coborroweraddress1,
      coBorrowerAddress2: input.coborroweraddress2,
      coBorrowerCity: input.coborrowercity,
      coBorrowerState: input.coborrowerstate,
      coBorrowerZip: input.coborrowerzip,
      coBorrowerCountry: input.coborrowercountry,
      coBorrowerHomeStatus: input.coborrowerhomestatus,
      coBorrowerPhnCountryCode: input.coborrowerphncountrycode,
      coBorrowerPhnCityCode: input.coborrowerphncitycode,
      coBorrowerHomePhone: input.coborrowerhomephone,
      coBorrowerMobilePhone: input.coborrowermobilephone,
      coBorrowerWorkPhone: input.coborrowerworkphone,
      coBorrowerPhoneExtension: input.coborrowerphoneextension,
      coBorrowerEmail: input.coborroweremail,
      coBorrowerEmployer: input.coborroweremployer,
      coBorrowerJobTitle: input.coborrowerjobtitle,
      coBorrowerEmployerAddress: input.coborroweremployeraddress,
      coBorrowerEmployerCity: input.coborroweremployercity,
      coBorrowerEmployerState: input.coborroweremployerstate,
      coBorrowerEmployerZip: input.coborroweremployerzip,
      coBorrowerEmployerPhone: input.coborroweremployerphone,
      coBorrowerDriverLicense: input.coborrowerdriverlicense,
      settlementOfferDate: input.settlementofferdate,
      settlementExpirationDate: input.settlementexpirationdate,
      paymentAmtpriortoChargeOff: input.paymentamtpriortochargoff,
      paymentFrequency: input.paymentfrequency,
      dayPaymentWasScheduledForProcessing:
        input.daypaymentwasscheduledforprocessing,
      language: input.language,
      totalNumberOfNSFFees: input.totalnumberofnsffees,
      totalNSFFeesAmt: input.totalnsffeesamt,
      recoveryOpeningBalance: input.recoveryopeningbalance,
      outstandingRecoveryBalance: input.outstandingrecoverybalance,
      totalRecoveryPayments: input.totalrecoverypayments,
      chargeOffBalance: input.chargeoffbalance,
      totalPaymentsSinceChargeOff: input.totalpaymentssincechargeoff,
      mailingAddress1: input.mailingaddress1,
      mailingAddress2: input.mailingaddress2,
      mailingCity: input.mailingcity,
      mailingState: input.mailingstate,
      mailingZip: input.mailingzip,
      mailingCountry: input.mailingcountry,
    }

    return dataRow
  }

  const handleConfirmProcessAccountFile = () => {
    setIsFetching(true)
    const accountFileData: UpdateAccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      accountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const params = {
      creditorId,
      accountFileData,
      file: fileUploaded,
    }
  }

  return (
    <>
      <ValidationResult
        fileMap={fileMap}
        fileOptions={{
          validateTotalBalance: true,
          formatDate: profileCountry(),
        }}
        fileData={fileData}
        processmentErrors={processmentErrors}
        isFetchingProceed={isFetching}
        onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
        onClickProceedButton={handleConfirmProcessAccountFile}
      />
    </>
  )
}

export default UpdateAccountFileValidation
